import React from 'react';

import LatestTopic from './components/LatestTopic';

function App() {
  return (

      <div className="bmjWidget">
          <LatestTopic widgetParams={{feedItems:20, widgetHead:'Cardiology latest'}}/>
      </div>

  );
}

export default App;
