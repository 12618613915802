// a function which convert an object literal to URL query string
export const objectToQueryString = object => {
    var parameters = []
    for (var property in object) {
      if (object.hasOwnProperty(property)) {
        parameters.push(encodeURI(property + "=" + object[property]))
      }
    }
    return parameters.join("&")
  }
  
  // a custom sort for JSON feed response from Unsilo service
export  const customSort = (a, b) => {
    return (
      new Date(b.publicationDate).getTime() -
      new Date(a.publicationDate).getTime()
    )
  }
  
  