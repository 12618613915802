import React from "react"
import { SvgIcon } from "@material-ui/core"
import { useFetch } from "../utils/UseFetch"
import { objectToQueryString, customSort } from "../utils/utils"


const LatestTopics = ({ widgetParams, classes }) => {
  const {
    endPoint = "latest-topics",
    feedApi = `https://d6fuz3wpi7xmr.cloudfront.net/bmj/${endPoint}`,
    collectionId = 67,
    classId = 1121,
    feedItems, 
    widgetHead = "Latest Cardiology",
    ...restParam
  } = widgetParams

  const queryStrings = objectToQueryString(restParam).replace(/&$/, "")

  const [data, loading] = useFetch(
    `${feedApi}?classId=${classId}&collectionId=${collectionId}&${queryStrings}`
  )

  return (
    <>

      <div className="latestTopic">
        <h3 className="widget-title">
          {widgetHead}
        </h3>
        {loading ? (
          "Loading..."
        ) : (
          <div className="topicCard">
           

              { data.message.export.documents
                .sort(customSort) // custom sort by pulicationDate
                .slice(0, feedItems ? feedItems : data.message.export.documents.length-1)
                .map(
                  (
                    {
                      authors,
                      customFields,
                      doi,
                      id,
                      journal,
                      publicationDate,
                      publisher,
                      rights,
                      title,
                      types
                    },
                    i
                  ) => {
                    const d = new Date(publicationDate)
                    console.log(customFields['section-heading'])
                    const pubDate = `${d.getDate()} ${d.toLocaleString('en-uk', {month: 'long'})} ${d.getFullYear()}`
                    return (
                      <p className="wrapArticle" key={id}>
                              <span className="articleType">
                              { customFields && customFields['section-heading'] &&  customFields['section-heading'].toString() ?  ` ${customFields['section-heading'].toString()} ` : null }<br/>
                              </span>
                              <a  className="articleTitle"
                                href={`https://www.doi.org/${doi}`}
                              >
                                {title}
                              </a>
                              { rights && rights.toString() ? ( <OpenAccessIcon /> ) : null } 
                              <span className="pubDate">{` ${pubDate} `}</span>
                              <span className="pubDate">{` ${journal} `}</span>
                      </p>
                    )
                  }
                )}
          </div>
        )}
      </div>
    </>
  )
}


export const OpenAccessIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
    </SvgIcon>
  );
}


export default LatestTopics
